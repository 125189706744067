/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import BlogList from "../components/blog-list"
import Container from "../components/container"
import Copy from "../components/copy"
import HeadingLink from "../components/heading-link"
import Image from "../components/image"
import LinkList from "../components/link-list"
import MotionWrapper from "../components/motion-wrapper"
import Slab from "../components/slab"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const {
    workEntries,
    specialismEntries,
    posts,
    entry: [entry],
  } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      isHome: true,
      menuLinkHoverColor: "accent",
    })
  }, [updateHeaderState])

  const footerLinks = [
    {
      title: "View our work",
      slug: "/made",
    },
    {
      title: "Learn about our specialisms",
      slug: "/does",
    },
    {
      title: "Contact us",
      slug: "/contact",
    },
  ]

  return (
    <>
      <SEO
        description={entry.metaDescription}
        title={entry.metaTitle ? entry.metaTitle : entry.title}
        image={entry.metaImage[0].url}
      />
      <MotionWrapper footerLinks={footerLinks}>
        <Slab first bg="secondary" color="background">
          <Container>
            <Copy markup={entry.introText} copySx={{ fontSize: "1.5em" }} />
          </Container>
        </Slab>
        <Image
          {...entry.mainImage[0]}
          autoFormat={false}
          containerSx={{
            height: ["65vh", "65vh"],
            backgroundColor: "secondary",
          }}
          imageSx={{
            objectFit: "cover",
            objectPosition: "center top",
            height: "100%",
          }}
        />
        <Slab bg="primary" color="background">
          <Container>
            <HeadingLink text="Featured work" to="/made" />
            <LinkList links={workEntries} parentPrefix="/made" />
          </Container>
        </Slab>
        <Slab bg="muted">
          <Container>
            <HeadingLink text="Specialisms" to="/does" />
            <LinkList links={specialismEntries} parentPrefix="/does" />
          </Container>
        </Slab>
        <Slab>
          <Container>
            <HeadingLink text="From the blog" to="/blog" />
            <BlogList posts={posts} />
          </Container>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const IndexPageQuery = graphql`
  {
    craft {
      entry: entries(section: "home", limit: 1) {
        title
        ... on craft_home_home_Entry {
          introText
          mainImage {
            url
            ... on craft_images_Asset {
              altText
              credit
            }
          }
          servePeopleWellIntroduction
          metaTitle
          metaDescription
          metaImage {
            url
          }
        }
      }
      workEntries: entries(section: "work", limit: 4) {
        title
        slug
      }
      specialismEntries: entries(section: "specialisms") {
        title
        slug
      }
      posts: entries(type: ["blogPost"], limit: 3) {
        ... on craft_blog_blogPost_Entry {
          typeHandle
          id
          title
          slug
          postDate
        }
      }
    }
  }
`

export default IndexPage
