/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import Heading from "./heading"
import { motion } from "framer-motion"

import transition from "../utils/framer-transition"

const HeadingLink = ({ to, text, headingSx, fullOpacity }) => {
  const textMotionVariants = {
    initial: { x: -60, opacity: 0 },
    enter: {
      x: 0,
      opacity: fullOpacity ? 1 : 0.5,
      transition: { ...transition },
    },
    exit: {
      x: -60,
      opacity: 0,
      transition: { ...transition },
    },
  }

  return (
    <motion.div variants={textMotionVariants}>
      <Link
        to={to}
        sx={{
          textDecoration: "none",
        }}
      >
        <Heading headingSx={headingSx}>{text}</Heading>
      </Link>
    </motion.div>
  )
}

HeadingLink.defaultProps = {
  fullOpacity: false,
}

export default HeadingLink
